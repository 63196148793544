import React from 'react';
import axios from "axios";
import '../css/community.css';
import restfulApiConfig from './config.js';
import Header from "./header";
// import PeopleGeeks from '../img/peoplegeeks.png';
// import NomadsTalk from '../img/nomadstalk.png';
// import DelocateRemote from '../img/delocateremote.png';
// import RemoteWork from '../img/remotework.jpg';
// import GSinUXdesign from '../img/GSinUXdesign.png';
// import Bootcampers from '../img/bootcampers.png';
// import UXmastery from '../img/UXmastery.png';
// import Startupstudy from '../img/startupstudy.jpeg';

//コミュニティテーブルから画像を引っ張ってきて配列に入れる
// 
//    NomadsTalk,DelocateRemote,
//     RemoteWork,GSinUXdesign,Bootcampers,UXmastery,Startupstudy

class CommunitySelect extends React.Component {
    constructor(props) {
      super(props);
      this.state = {communities_data:[]};
  
    //   this.onChange = this.onChange.bind(this);
    //   this.handleSubmit = this.handleSubmit.bind(this);
    }
    componentDidMount() {
      document.title = "Community Select";
        axios
        .get(restfulApiConfig.api+"community", {
          withCredentials: true,
        })
        .then((res) => {
          this.setState({communities_data: res.data});
          // console.log(this.state.communities_data)
        })
        .catch((err) => {
          console.log(err);
        });
  
    }
  
    render() {
      return (
        <>
        <Header/> 
        <div className="communities_box">
        <h2 className="title_community_select">Please choose community from the following options.</h2>
        <div className="community_announce">No communities to join? Having issues connecting Slack communities? <br/>
          Please check 
          <a href="https://www.notion.so/trypotlatch/V1-rev-2021-02-25-563abefa608a4e52b9e3fa8eb1003fca">
          our Release Notes.
          </a>
        </div>
          <ul className="communities_list communities_box_margin">
            { this.state.communities_data.map((val) => 
            <div>
            <li key={val} className="community_section">
                <a href={restfulApiConfig.api+"auth-slack/" + val.id}>
                <img src={val.image} alt="sorry"/>
                </a>                
            </li>
            <p class="font-body text-blue-dark">{val.name}</p>
            </div>
            )} 
          </ul>
        </div>
        </>
      );
    }
  }

export default CommunitySelect;