import React from 'react';
import Header from "./header";
import '../css/review.css';
import restfulApiConfig from './config.js';
import axios from "axios";
// import {
//     // BrowserRouter as Router,
//     // // Switch,
//     // Route,
//     Link,
//     Prompt,
//   } from 'react-router-dom';

class Review extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
          user_data: [],
          review_data:[],
        };
  
      this.handleChange = this.handleChange.bind(this);
      this.handleSubmit = this.handleSubmit.bind(this);
    }

componentDidMount(){
    document.title = "Review";
    axios.get(restfulApiConfig.api+"user",{withCredentials: true})
    .then((res)=>{
    this.setState({user_data: res.data});
    console.log(this.props.match.params.id)
    }).catch((res)=>{
    console.log(res)
    });
    }

    handleChange(event) {
        this.setState({review_data: event.target.value});
      }
    
      handleSubmit() {
        const data = {
          "review": this.state.review_data,
        };
        // console.log(intro_data);
        axios.put(
          restfulApiConfig.api+"reviews/"+this.props.match.params.id,
          data,
          { withCredentials: true },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          console.log(res)
        })
        .catch((err) => {
          console.log(err);
        });
    }

render() {
    return(

        <>

        <Header/>   
        <div className="profile_box">
            Review
        </div>
        <div className="intro_input">
            <textarea
              id="user_intro"
              value={this.state.value}
              onChange={this.handleChange}
            />
        </div>
        <button className="font-display font-bold text-white bg-blue-dark rounded-2xl py-3 px-32px review_submit_button" onClick={this.handleSubmit}>
                  Submit
        </button>
        </>
    
    );
    }
}

export default Review;
