import React from "react";
import "../css/location.css";
import Header from "./header";
import PotlatchLogo from '../img/PotlatchLogo.svg';
import { Link } from "react-router-dom";
import axios from "axios";

// const endpoint = "https://v1api-dev.potlatch.io/user"
const endpoint = "https://v1api.potlatch.io/user"

class UpdateLocation extends React.Component {
  constructor(props) {
    super(props);
    this.state = { value: "" };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount(){
    document.title = "Location Update";
    axios.get(endpoint,{withCredentials: true})
    .then((res)=>{
    this.setState({value: res.data.location});
    console.log(this.state.value)
    }).catch((res)=>{
    console.log(res)
    });
    }

  handleChange(event) {
    this.setState({ value: event.target.value });
  }

  handleSubmit() {
    const location_data = {
      location: this.state.value,
    };
    console.log(location_data);
    axios.patch(
      endpoint,
      location_data,
      {withCredentials: true},
      {
        headers : {
        'Content-Type': 'application/json',
      }}
      ).then((res) => {
        console.log(res)

    }).catch((err) => {
        console.log(err)
    }
    )
  }

  render() {
    return (
      <>
        <Header/>   
        <div className="location_box">
        <div className="location_logo"><img src={PotlatchLogo} alt="ロゴ"/></div>
        <h2 className="location_h2">
          Where are you currently located?
        </h2>
        <h4 className="location_h4">We are going to ask you several questions about you. Based on the information you are going to provide, we are going to find your best meeting partner.
        <br/>At first, could you provide where are you based at?
        </h4>
        <h4 className="country_city">Country/City</h4>
        <div className="location_input">
          <input
            type="text"
            name="name"
            id="user_location"
            value={this.state.value}
            onChange={this.handleChange}
          />
        </div>
        <div
          className="location_button"
          onClick={this.handleSubmit}
        >
          <Link to="/objectives" className="location_link">
          Update
          </Link>
        </div>
      </div>
      </>
    );
  }
}

export default UpdateLocation;
