import React from 'react';
import '../css/intro.css';
import restfulApiConfig from './config.js';
import {Link} from 'react-router-dom';
import axios from "axios";
import Header from "./header";


class UpdateIntro extends React.Component {
    constructor(props) {
      super(props);
      this.state = {value: ''};
  
      this.handleChange = this.handleChange.bind(this);
      this.handleSubmit = this.handleSubmit.bind(this);
    }
 
    componentDidMount(){
      document.title = "Introductio Update";
        axios.get(restfulApiConfig.api+"user",{withCredentials: true})
        .then((res)=>{
        this.setState({value: res.data.introduction});
        // console.log(this.state.value)
        }).catch((res)=>{
        console.log(res)
        });
        }

    handleChange(event) {
      this.setState({value: event.target.value});
    }
  
    handleSubmit() {
      const intro_data = {
        "introduction": this.state.value,
      };
      // console.log(intro_data);
      axios.patch(
        restfulApiConfig.api+"user",
        intro_data,
        {withCredentials: true},
        {
          headers : {
          'Content-Type': 'application/json',
        }}
        ).then((res) => {
          // console.log(res)
  
      }).catch((err) => {
          console.log(err)
      })
    }
  
    render() {
      return (

        <>
        <Header/>   
        <div className="update_wrapper">
          <div className="register_flow_box">
            <h2 className="intro_h2">Okay, can you tell us more about yourself?</h2>
            <h4 className="intro_h4">This is how make we're going to introduce you.</h4>
          <div className="intro_input">
          <textarea  id="user_intro" value={this.state.value} onChange={this.handleChange}/>
          </div >
          <div className="navigation_wrapper">
              <div
                className="register_back_button"
              >
                <Link to="/" className="register_back_link">
                  Back
                </Link>
              </div>
              
              <div
                className="register_button"
                onClick={this.handleSubmit}
              >
                <Link to="/user_profile" className="register_link">
                  Update
                </Link>
              </div>
            </div>
          </div>
        </div>
        </>

      );
    }
  }

export default UpdateIntro;