import React from "react";
import Header from "./header";
import ChatComponent from "./chatcomponent2";
import axios from 'axios';
import "../css/potlatch_mtg.css";
import restfulApiConfig from './config.js';
import {
  Link,
  Prompt
} from 'react-router-dom';

class PotlatchMeeting extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
          matched_data: [],
          loading:true,
          wrong_url:false,
          not_yet_mtg_time:false,
        };
        
        this.onUnload = this.onUnload.bind(this);      
    }

componentDidMount(){
    document.title = "Potlatch Meeting";
    window.addEventListener("beforeunload", this.onUnload);
    // console.log(this.props)
    axios.get(restfulApiConfig.api+"dashboard",{withCredentials: true})
    .then((res)=>{
        // そもそもマッチがあるか
        if(res.data.matches){
        // マッチの中にパラメータと同じidがあるか探す
            const match = res.data.matches.filter(matchid => {
                return matchid.id === this.props.match.params.id;
              });
        // あればstateに保存
            if(match){
                this.setState({matched_data: match}); 
                console.log(this.state.matched_data[0])
                console.log(match[0].streamChannelId)
                // streamIDとdailyURLがあれば繋げて
                if(match[0].streamChannelId&&match[0].dailyUrl) {
                    function createFrameAndJoinRoom(id) {
                        window.callFrame = window.DailyIframe.createFrame(
                            document.getElementById("frame"), {
                            showLeaveButton: false,
                            showFullscreenButton: true,
                            // iframeStyle: {
                            //     position: 'relative',
                            //     top: "4%",
                            //     bottom: "4%",
                            //     left: 0,
                            //     width: '99.6%',
                            //     height: '92%',
                            // }
                        });
                        
                        // console.log("Joining " + roomName);
                        // console.log("https://" + config.DAILY_SUBDOMAIN + ".daily.co/" + roomName);
                        window.callFrame.join({ url: "https://potlatch.daily.co/"+id,})
                    }
                    
                    this.setState({loading: false}); 
                    createFrameAndJoinRoom(this.props.match.params.id)          
                }else{
                    // streamIDとdailyURL無ければ、not_yet_mtg_timeにする
                    this.setState({loading: false});
                    this.setState({not_yet_mtg_time: true});
                }
            }else{
                // matchがundefindもしくはない場合パラメータとidが一致していないので
                this.setState({loading: false});
                this.setState({wrong_url: true});
            }
                  
        }else{
            // res.data.matchesなければとりあえずダッシュボードに
            return window.location.href = '/'
        }
        
    }).catch((error)=>{
        console.log(error)
        if(error.response.status===400){
            return window.location.href = '/LinkedInLogin'
        }
    });
    }

    onUnload = (e) => {
        let custom_msg="Are you sure to leave?"
        if(custom_msg){
            e.preventDefault();
            e.returnValue = custom_msg;
        }

        console.log("Hi")
        return custom_msg
      };
    
      componentWillUnmount() {
        // remove
        window.removeEventListener('beforeunload', this.onUnload);
      }


render() {
if(this.state.loading){
    return <h1 className="potlatch_mtg_h1">Loading</h1>
}else if(this.state.wrong_url){
    return (
        <>
    <h1 className="potlatch_mtg_h1">Incorrect Url</h1>
    <div
    className="potlatch_mtg_back_button"
  >
    <Link to="/" className="register_back_link">
      Back
    </Link>
  </div>
  </>)

}
else if(this.state.not_yet_mtg_time){
    return (
    <>
    <Header/>
    <h1 className="potlatch_mtg_h1">The meeting venue is not ready yet.
        <br/>Please come when the meeting is scheduled.</h1>
    <div
    className="potlatch_mtg_back_button"
  >
    <Link to="/" className="register_back_link">
      Back
    </Link>
  </div>
  </>)
}
    return(

        <> 
        <Prompt
        when={this.props}
        message="Are you sure you want to leave?"
        /> 
        <Header/>
        <div className="potlatch_mtg_wrapper">
            <div id="frame"className="mtg_screen"></div>
            <div className="chat_space">
                <div className="potlatch_mtg_partner_bio">
                    <div className="potlatch_mtg_partner_nav">
                        <div className="potlatch_mtg_partner_image"><img src={this.state.matched_data[0].partner.imageUrl} alt="meeting_partner_image"/></div>
                        <div className="potlatch_mtg_partner_name">{this.state.matched_data[0].partner.nameFirst}</div>
                        <div>nav</div>
                    </div>
                    <div></div>
                    <div>{this.state.matched_data[0].partner.introduction}</div>
                </div>
                <ChatComponent channel_id={this.state.matched_data[0].streamChannelId}/>

            </div>
        </div>
        {/* <div
            className="potlatch_mtg_review_button"
        >
            <Link to={"/review/" + this.props.match.params.id} className="potlatch_mtg_review_link">
            Review
            </Link>
        </div> */}
        </>
    
    );
    }
}

export default PotlatchMeeting;