const Config = () => {
    if (process.env.NODE_ENV === 'test') {
      // test
      return {
        restful: {
          api: process.env.REACT_APP_TEST_API,
          chatKey: process.env.REACT_APP_TEST_CHAT_KEY
        }
      }
    } else if (process.env.NODE_ENV === 'production') {
      // production
      return {
        restful: {
          api: process.env.REACT_APP_PROD_API,
          chatKey: process.env.REACT_APP_PROD_CHAT_KEY
        }
      }
    }
    // development
    return {
      restful: {
        api: process.env.REACT_APP_DEV_API,
        chatKey: process.env.REACT_APP_DEV_CHAT_KEY
      }
    }
  }
  const restfulApiConfig = Config().restful
export default restfulApiConfig