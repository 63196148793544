import React from 'react';
import '../css/chat.css';
import Header from "./header";
import ChatComponent from "./chatComponent";


class chat extends React.Component { 
  constructor(props) {
    super(props);
    this.state = {
      data:{},
    };

  }

  componentDidMount() {
    document.title = "Chat";
  }
  
      render() {

        

        return (
            <>
            <div className="chat_wrapper">
              <Header/>     
              <ChatComponent/>       
            </div>
            </>
        );
      }
    }

export default chat;