import React from 'react';
import '../css/jobtitle.css';
import restfulApiConfig from './config.js';
import {Link} from 'react-router-dom';
import axios from 'axios';
import PotlatchLogo from '../img/PotlatchLogo.svg';
import bg from "../img/register_bakcground.png";
// import { configure } from '@testing-library/react';


class linkedinurl extends React.Component {
    constructor(props) {
      super(props);
      this.state = {value: ''};
      this.handleChange = this.handleChange.bind(this);
      this.handleSubmit = this.handleSubmit.bind(this);
    }
    
    componentDidMount(){
      document.title = "LinkedInUrl";
      axios.get(restfulApiConfig.api+"user",{withCredentials: true})
      .then((res)=>{
          console.log(res.data)
      if(res.data.linkedInUrl!==""){
      this.setState({value: res.data.linkedInUrl});
      console.log(this.state.value)
      }
      }).catch((res)=>{
      console.log(res)
      });
  }
  
  handleChange(event) {
    this.setState({value: event.target.value});
  }
    handleSubmit() {
      const linkedinurl_data = {
        linkedInUrl: this.state.value,
      };
      console.log(linkedinurl_data);
      axios.patch(
        restfulApiConfig.api+"user",
        linkedinurl_data,
        { withCredentials: true },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        console.log(res)
      })
      .catch((err) => {
        console.log(err);
      });
  }

  ui() {
    return (
      <div
        className="h-screen py-12 px-4 space-y-4 flex flex-col items-center text-blue-dark"
        style={{
          backgroundImage: `url(${bg})`,
        }}
      >
        <img className="h-16" src={PotlatchLogo} alt="ロゴ" />
        <div className="lg:w-720px flex flex-col space-y-8 rounded-2xl border-blue-dark border-2 py-32px px-24px bg-white">
          <div className="flex flex-col space-y-2">
            <h2 className="font-display font-bold text-2xl">
            Could you share your LinkedIn url?
            </h2>
          </div>

          <input
            className="border-2 border-gray-3 hover:border-blue-dark focus:border-blue-dark rounded-xl py-2 px-2"
            value={this.state.value}
            onChange={this.handleChange}
          >
          </input>


          <div className="flex flex-row justify-between">
            <button className="font-display font-bold text-blue-dark bg-white rounded-2xl py-3 px-32px">
              <Link to="/intro" className="register_back_link">
                Back
              </Link>
            </button>

            <div className="flex flex-row justify-end space-x-4 items-center">
                <p>Step 4 of 5</p>
                <button className="font-display font-bold text-white bg-blue-dark rounded-2xl py-3 px-32px" 
                onClick={this.handleSubmit}>
                <Link to="/availability" className="register_link">
                    Continue
                </Link>
                </button>
            </div>
          </div>
            
        </div>
      </div>
    );
  }

  render() {
    return this.ui();
  }
}

export default linkedinurl;
