import React from 'react';
import '../css/intro.css';
import restfulApiConfig from './config.js';
import {Link} from 'react-router-dom';
import axios from "axios";
import PotlatchLogo from '../img/PotlatchLogo.svg';
import bg from "../img/register_bakcground.png";

class Intro extends React.Component {
    constructor(props) {
      super(props);
      this.state = {value: ''};
  
      this.handleChange = this.handleChange.bind(this);
      this.handleSubmit = this.handleSubmit.bind(this);
    }
    componentDidMount(){
      document.title = "Share Introduction";
      axios.get(restfulApiConfig.api+"user",{withCredentials: true})
      .then((res)=>{
      if(res.data.introduction!==""){
      this.setState({value: res.data.introduction});
      }
      // console.log(this.state.value)
      }).catch((res)=>{
      console.log(res)
      });
      }
    handleChange(event) {
      this.setState({value: event.target.value});
    }
  
    handleSubmit() {
      const intro_data = {
        "introduction": this.state.value,
      };
      // console.log(intro_data);
      axios.patch(
        restfulApiConfig.api+"user",
        intro_data,
        { withCredentials: true },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        // console.log(res)
      })
      .catch((err) => {
        console.log(err);
      });
  }

  ui() {
    return (
      <div
        className="h-screen py-12 px-4 space-y-4 flex flex-col items-center text-blue-dark"
        style={{
          backgroundImage: `url(${bg})`,
        }}
      >
        <img className="h-16" src={PotlatchLogo} alt="ロゴ" />
        <div className="lg:w-720px flex flex-col space-y-8 rounded-2xl border-blue-dark border-2 py-32px px-24px bg-white">
          <div className="flex flex-col space-y-2">
            <h2 className="font-display font-bold text-2xl">
              Okay, can you tell us more about yourself?
            </h2>
            <h3 className="font-display text-xl">
              This is how make we're going to introduce you.
            </h3>
          </div>

          <textarea
            id="user_intro"
            className="w-full h-24 max-h-36 px-4 py-2 rounded-xl border-2 border-gray-3 hover:border-blue-dark focus:border-blue-dark"
            value={this.state.value}
            onChange={this.handleChange}
            placeholder=" Hi I'm Thomas Tiga Koboyashi, CEO of Potlatch. 
            My area of interest is community and matching.
            I'm good at user research and project management.
            Looking forward to connecting with interesting Product Managers"
          />

          <div className="flex flex-row justify-between">
            <button className="font-display font-bold text-blue-dark bg-white rounded-2xl py-3 px-32px">
              <Link to="/objectives" className="register_back_link">
                Back
              </Link>
            </button>

            <div className="flex flex-row justify-end space-x-4 items-center">
              <p>Step 3 of 5</p>
              <button className="font-display font-bold text-white bg-blue-dark rounded-2xl py-3 px-32px" onClick={this.handleSubmit}>
                <Link to="/linkedinurl" className="register_link">
                  Continue
                </Link>
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  oldUI() {
    return (
      <div className="register_flow_wrapper">
        <div className="register_flow_logo">
          <img src={PotlatchLogo} alt="ロゴ" />
        </div>
        <div className="register_flow_box">
          <h2 className="intro_h2">
            Okay, can you tell us more about yourself?
          </h2>
          <h4 className="intro_h4">
            This is how make we're going to introduce you.
          </h4>
          <div className="intro_input">
            <textarea
              id="user_intro"
              value={this.state.value}
              onChange={this.handleChange}
            />
          </div>
          <div className="navigation_wrapper">
            <div className="register_back_button">
              <Link to="/objectives" className="register_back_link">
                Back
              </Link>
            </div>
            <p>Step 2 of 3</p>
            <div className="register_button" onClick={this.handleSubmit}>
              <Link to="/jobtitle" className="register_link">
                Continue
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    return this.ui();
  }
}

export default Intro;
