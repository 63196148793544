import React from 'react';
import axios from "axios";
import { StreamChat } from 'stream-chat';
import restfulApiConfig from './config.js';
import { 
    Chat, Channel, 
    ChannelList, Window, 
    ChannelHeader, MessageList, 
    MessageInput, Thread, MessageSimple
} from 'stream-chat-react';
import 'stream-chat-react/dist/css/index.css';

// initialize the Chat client with my API key
const chatClient = StreamChat.getInstance(restfulApiConfig.chatKey);

class chatComponent2 extends React.Component { 
  constructor(props) {
    super(props);
    this.state = {
      user_data:{},
      partner_data:[],
      loading:true,
      filters:null,
      sort:null,
    };

  }

  componentDidMount() {
    document.title = "Chat";
      axios
      .get(restfulApiConfig.api+"dashboard", {
        withCredentials: true,
      })
      .then((res) => {
        console.log(this.props.channel_id)
        console.log(res.data)
        this.setState({data: res.data.me});
        chatClient.connectUser(
          {
              id:res.data.me.id,
              name: res.data.me.nameFirst,
              image: res.data.me.imageUrl,
          },
          res.data.me.streamToken,
      );
        const filter ={id: { $in: [this.props.channel_id] }}
        this.setState({filter:filter})
        // this.setState({filter:{ type: 'messaging', members: { $in: [this.props.partner_id] } }})
      this.setState({loading:false})
      })
      .catch((err) => {
        console.log(err);
      });

  }
  
      render() {

        
        if(this.state.loading){
          return <h1>Loading</h1>
        }
        return (
            <>
            <div className="chat_wrapper">           
          <Chat client={chatClient} theme={'messaging light'} className="chat">
            <ChannelList
              filters={this.state.filter}
              // sort={this.sort}
            //   List={ChannelListMessenger}
            //   Preview={ChannelPreviewMessenger}
              />
            <Channel >
              <Window>
                <ChannelHeader />
                <MessageList  />
                {/* TypingIndicator={TypingIndicator} */}
                <MessageInput   focus/>
                {/* Input="" */}
              </Window>
              <Thread Message={MessageSimple}/>
            </Channel>
          </Chat>
          </div>
          </>
        );
      }
    }

export default chatComponent2;