import React from 'react';
import {Link} from 'react-router-dom';
import Select from 'react-select'
import axios from "axios";
import Header from "./header";
import '../css/objectives.css';
import restfulApiConfig from './config.js';


const objectives_options = [
  { value: 'Brainstorm with peers', label: 'Brainstorm with peers' },
  { value: 'Grow your team', label: 'Grow your team' },
  { value: 'Start a company', label: 'Start a company' },
  { value: 'Explore other company', label: 'Explore other company' },
  { value: 'Business development', label: 'Business development' },
  { value: 'Invest', label: 'Invest' },
  { value: 'Explore new projects', label: 'Explore new projects' },
  { value: 'Mentor others', label: 'Mentor others' },
  { value: 'Organize events', label: 'Organize events' },
  { value: 'Raise funding', label: 'Raise funding' },
  { value: 'Find a cofounder', label: 'Find a cofounder' },
  { value: 'Meet intresting people', label: 'Meet intresting people' },
]

const objectives = [];
const obj_data = {
  "objectives" : objectives
};
class Objectives extends React.Component {
    constructor(props) {
      super(props);
      this.state = {value:""};
  
      this.onChange = this.onChange.bind(this);
      this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount(){
      document.title = "Objective Update";
        axios.get(restfulApiConfig.api+"user",{withCredentials: true})
        .then((res)=>{
          if(res.data.objectives!==""){
            let obj_options =[]
            obj_options = res.data.objectives.map((obj)=>{
              return {"value": obj, "label": obj}
            })
            this.setState({value: obj_options});
            }
        }).catch((res)=>{
        console.log(res)
        });
        }

        
  
    onChange(value) {
      this.setState({value: value});
      
      for(let i = 0; i< value.length ; i++){
        objectives[i] = value[i].value;
      }
      // console.log(obj_data)
    }
  
    handleSubmit() {
      axios.patch(
        restfulApiConfig.api+"user", 
        obj_data, 
        {withCredentials: true},
      {
        headers : {
        'Content-Type': 'application/json',
      }}    
        ).then((res) => {
          // console.log(res)
          // console.log(obj_data)
      }).catch((err) => {
          console.log(err)
      }
      )
    }
  
    render() {
      return (

        <>
        <Header/>   
        <div className="update_wrapper">
          <div className="register_flow_box">
            <h2 className="objectives_jobtitle_h2">
              What topics are you interested in?
            </h2>
            <div className="objectives_h4">This helps us match you with people who share your interests.</div>
            <Select value={this.state.value} onChange={this.onChange} options={objectives_options} className="objectives_selections" isMulti  />
            <div className="navigation_wrapper">
            <div
                className="register_back_button"
              >
                <Link to="/user_profile" className="register_back_link">
                  Back
                </Link>
              </div>
              <div
                className="register_button"
                onClick={this.handleSubmit}
              >
                <Link to="/user_profile" className="register_link">
                  Update
                </Link>
              </div>
            </div>
          </div>
        </div>
        </>

      );
    }
  }

export default Objectives;