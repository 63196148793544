import React from "react";

class Editor extends React.Component{
    constructor(props){
        super(props);
        this.state = {
          
        };
    }

    componentDidMount(){

    }

    render(){
        return(
        <>
        <div id="editor"></div>
        <iframe title="hackmd"width="100%" height="500" src="https://hackmd.io/features" frameborder="0"></iframe>
        {/* <script src="https://cdn.ckeditor.com/ckeditor5/26.0.0/classic/ckeditor.js"></script>
        <script>
            {this.ClassicEditor
        .create( document.querySelector( '#editor' ) )
        .catch( error => {
            console.error( error );
        } )}
        </script> */}
        </>
        )        
    }
}

export default Editor;