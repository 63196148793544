import React from "react";
import Header from "./header";
import axios from "axios";
import "../css/dashboard.css";
import restfulApiConfig from './config.js';
import {
  // BrowserRouter as Router,
  // // Switch,
  // Route,
  // Redirect,
  Link,
} from "react-router-dom";

let user_timez;
let result = [];

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user_data: "",
      user_community: [],
      user_meetings: [],
      user_matches: [],
      user_upcoming: [],
      loading: true,
      user_timez: "",
    };
  }

  componentDidMount() {
    document.title = "Dashboard";
    // Get User Timezone
    user_timez = Intl.DateTimeFormat().resolvedOptions().timeZone;
    this.setState({ user_timez: user_timez });
    // Get User Information
    axios
      .get(restfulApiConfig.api+"dashboard", {
        withCredentials: true,
      })
      .then((res) => {
        // console.log(res.data.pendingMeetings)
        // Store user data received in response in the state
        if (res.data) {
          // set user data
          this.setState({ user_data: res.data.me });
          // set user
          this.setState({ user_community: res.data.communities });
          // set user applied meeting (The deadline has not passed.)
          let result = this.changeDateFormat(res.data.pendingMeetings)
          this.setState({ user_meetings: result});
          // set user matched history
          this.setState({ user_matches: res.data.matches });
          // set user upcoming meeting
          this.setState({ user_upcoming: res.data.upcomingMeetings });          
        }        
        // change loading state to false       
        this.setState({ loading: false });
      })
      .catch((err) => {
        // <Redirect to="/LinkedInLogin" />
        console.log(err);
        return (window.location.href = "/LinkedInLogin");
      });
  }

// change format of meeting data startAt 
  changeDateFormat(date_data){
    for(let i = 0; i < date_data.length; i++){
        // Change the notation to the user's time zone and add only the startAt.
        let selected = new Date(date_data[i].startAt).toLocaleString({
          timeZone: user_timez,
        });
        result.push(selected);
    }
    return result;
  }

  render() {
    let appliedMeeting = [];
    let matchedMeeting = [];
    for (let g = 0; g < this.state.user_meetings.length; g++) {
      let mtg_start_time = new Date(this.state.user_meetings[g]);
      let mNames = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      // var dNames = ['Sun', 'Mon', 'Tue', 'Wed', 'Thur', 'Fri', 'Sat'];
      let hourStr = mtg_start_time.getHours() < 12 ? "AM" : "PM";
      let user_time =mNames[mtg_start_time.getMonth()] +" "
      +mtg_start_time.getDate() +"th"+mtg_start_time.getHours() +" "+hourStr;
      if (!this.state.user_matches) {
        for (let l = 0; l < this.state.user_matches.length; l++) {
          let um = new Date(this.state.user_matches[l].startAt).toLocaleString({
            timeZone: user_timez,
          });
          if (this.state.user_meetings[g] === um) {
            matchedMeeting.push(user_time);
          } else {
            appliedMeeting.push(user_time);
          }
        }
      } else {
        appliedMeeting.push(user_time);
      }
      appliedMeeting = appliedMeeting.filter(
        (x, i, self) => self.indexOf(x) === i
      );
      matchedMeeting = matchedMeeting.filter(
        (x, i, self) => self.indexOf(x) === i
      );
    }

    let user_matches = this.state.user_matches.length ? (
      this.state.user_matches.map((user_match) => (
        <button
          id="match-button"
          key={user_match.id}
          class="flex flex-col items-center space-y-2"
        >
          <a href={user_match.partner.linkedInUrl} target="_blank" rel="noopener noreferrer">
          <img
            src={user_match.partner.imageUrl}
            className="rounded-full w-72px h-72px bg-blue-dark"
            alt="partner"
          ></img>
          </a>
          <div id="spacer" class="h-2"></div>
          <p class="font-body text-blue-dark">{user_match.partner.nameFirst}</p>
        </button>
        // <ul className="matches_communities_ul">
        //   <li key={user_match.id} className="user_match_li">
        //     <img src={user_match.partner.imageUrl} alt="missing" />
        //   </li>
        // </ul>
      ))
    ) : (
      <p>There are no matches yet.</p>
    );
    // let community_section = this.state.user_community.length ? (
    //   this.state.user_community.map((community) => (
    //     <div class="flex flex-row">
    //       <button id="community-button" class="flex flex-col items-center">
    //         <img
    //           id="avatar"
    //           src={community.image}
    //           class="rounded-3xl w-24 h-24"
    //           alt="community"
    //         />
    //         {/* <p class="font-body text-blue-dark">{community}</p> */}
    //         <div id="spacer" class="h-2"></div>
    //         <p class="font-body text-blue-dark">{community.name}</p>
    //       </button>
    //     </div>
    //   ))
    // ) : (
    //   <p>
    //     You have not linked any communities yet.
    //     <br />
    //     For Potlatch to match you with others, you need to link a community.
    //     <br />
    //     Click on "Add new community" to get started.
    //   </p>
    // );

    if (this.state.loading) {
      return <h1>Loading</h1>;
    }
    return (
      <>
        <Header />
        <div id="content" class="flex flex-col-reverse md:flex-row">
          <div id="scrolling-content" class="flex flex-col flex-grow">
            <div id="matches" class="flex flex-col p-6">
              <div id="matches-elements" class="flex flex-row space-x-6">
                {user_matches}
                {/* <button id="match-button" class="flex flex-col items-center">
                  <div
                    id="avatar"
                    class="rounded-full w-72px h-72px bg-blue-dark"
                  ></div>
                  <div id="spacer" class="h-2"></div>
                  <p class="font-body text-blue-dark">Match 1</p>
                </button>
                <button id="match-button" class="flex flex-col items-center">
                  <div
                    id="avatar"
                    class="rounded-full w-72px h-72px bg-blue-dark"
                  ></div>
                  <div id="spacer" class="h-2"></div>
                  <p class="font-body text-blue-dark">Match 2</p>
                </button> */}
              </div>
            </div>

            <div
              id="communities"
              class="flex flex-row  p-6 items-start justify-evenly"
            >
              {/* {community_section} */}
              {/* <button
                id="link-new-community-btn"
                class="rounded-lg bg-blue-dark py-1 px-4 font-display font-bold text-white"
              >
                <Link className="dashboard_add_button_link" to="/community">
                  + community
                </Link>
              </button> */}
            </div>
          </div>
          <div id="sticky-content" class="p-6 md:w-480px">
            <div id="meetings" class="rounded-2xl bg-blue-baby p-6">
              <div id="upcoming-meetings" class="flex flex-col">
                <h4 class="font-bold font-display">Upcoming</h4>
                <div class="h-4"></div>
                <div id="upcoming-meetings-elements">
                  {this.state.user_matches.length ? (
                    this.state.user_upcoming.map((upcoming) => (
                      <div
                        key={upcoming.startAt}
                        className="flex flex-row bg-blue-baby border border-gray-3 border-opacity-16 items-center space-x-4 rounded-xl px-6 py-4"
                      >
                        <div className="flex flex-row -space-x-8">
                          <img
                            src={upcoming.partner.imageUrl}
                            className="rounded-full w-72px h-72px"
                            alt="Sorry"
                          />
                        </div>
                        <div className="flex flex-col items-start justify-start space-y-2">
                          <div className="flex flex-col justify-start items-start">
                            <h3 className="font-display">
                              {upcoming.partner.nameFirst}
                            </h3>
                            <p className="font-body">
                              {new Date(upcoming.startAt).toLocaleString({
                                timeZone: this.state.user_timez,
                              })}
                            </p>
                          </div>
                          <button
                            id="link-new-community-btn"
                            class="rounded-lg bg-blue-dark py-1 px-4 font-display font-bold text-white"
                          >
                            <Link to={"/PotlatchMeeting/" + upcoming.id}>
                              Open meeting
                            </Link>
                          </button>
                        </div>
                      </div>
                    ))
                  ) : (
                    <p class="font-body text-lg">No upcoming meetings yet.</p>
                  )}
                </div>
              </div>
              <div class="h-6"></div>
              <div id="divider" class="h-px bg-gray-2"></div>
              <div class="h-6"></div>
              <div id="available-slots" class="flex flex-col">
                {/* <div class="flex flex-row justify-between items-center">
                  <h4 class="font-bold font-display">Applied</h4>
                  <button
                    id="add-new-slot-btn"
                    class="rounded-lg bg-blue-dark py-1 px-4 font-display font-bold text-white"
                  >
                    <Link to="/selectmeetingtime">+ slot</Link>
                  </button>
                </div> */}

                {/* <div class="h-4"></div>
                <div className="flex flex-wrap space-x-2">
                  {appliedMeeting.length ? (
                    appliedMeeting.map((aplmtg) => (
                      <button
                        key={aplmtg}
                        class="rounded-2xl bg-turquoise py-2 px-3 flex flex-col items-center justify-center"
                      >
                        <p class="text-sm font-bold font-display">{aplmtg.substring(0, aplmtg.lastIndexOf("h")+1)}</p>
                        <p class="font-bold font-display">{aplmtg.slice(aplmtg.lastIndexOf("h")+ 1)}</p>
                      </button>
                    ))
                  ) : (
                    <p className="not_yet_match">
                      You have not applied to any meeting slots yet.
                    </p>
                  )}{" "}
                </div>
                <div id="available-slots-elements"></div> */}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Dashboard;
