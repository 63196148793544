import React from 'react';
import '../css/jobtitle.css';
import restfulApiConfig from './config.js';
import {Link} from 'react-router-dom';
import axios from 'axios';
import PotlatchLogo from '../img/PotlatchLogo.svg';
import bg from "../img/register_bakcground.png";
// import { configure } from '@testing-library/react';


class nextCareer extends React.Component {
    constructor(props) {
      super(props);
      this.state = {value: ''};
  
      this.handleChange = this.handleChange.bind(this);
      this.handleSubmit = this.handleSubmit.bind(this);
    }
    
    componentDidMount(){
      document.title = "next career";
      axios.get(restfulApiConfig.api+"user",{withCredentials: true})
      .then((res)=>{
          console.log(res.data)
      if(res.data.nextCareer!==""){
      this.setState({value: res.data.nextCareer});
      console.log(this.state.value)
      }
      }).catch((res)=>{
      console.log(res)
      });
  }
//   {this.state.user_data.nameFirst}{" "}
//   {this.state.user_data.nameLast}
  nextcareer_data = ["Start a company", "Change your career to a startup",
                     "Change your career to an enterprise", 
                     "Get better position in your current company",
                     "Other"];


    handleChange(event) {
      this.setState({value: event.target.value});
    }
  
    handleSubmit() {
      const nextCareer_data = {
        nextCareer: this.state.value,
      };
      // console.log(job_title_data);
      axios.patch(
        restfulApiConfig.api+"user",
        nextCareer_data,
        { withCredentials: true },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        console.log(res)
      })
      .catch((err) => {
        console.log(err);
      });
  }

  ui() {
    return (
      <div
        className="h-screen py-12 px-4 space-y-4 flex flex-col items-center text-blue-dark"
        style={{
          backgroundImage: `url(${bg})`,
        }}
      >
        <img className="h-16" src={PotlatchLogo} alt="ロゴ" />
        <div className="lg:w-720px flex flex-col space-y-8 rounded-2xl border-blue-dark border-2 py-32px px-24px bg-white">
          <div className="flex flex-col space-y-2">
            <h2 className="font-display font-bold text-2xl">
            Welcome,  <br />
            What kind of career path do you want to pursue in the future?
            </h2>
          </div>

          <select
            className="border-2 border-gray-3 hover:border-blue-dark focus:border-blue-dark rounded-xl py-2 px-2"
            value={this.state.value}
            onChange={this.handleChange}
          >
            <option value="" className="jt_option"></option>
            {this.nextcareer_data.map((d) => (
              <option className="jt_option" value={d} key={d}>
                {d}
              </option>
            ))}
          </select>
          <div className="flex flex-row justify-end space-x-4 items-center">
            <p>Step 1 of 5</p>
            <button className="font-display font-bold text-white bg-blue-dark rounded-2xl py-3 px-32px" 
            onClick={this.handleSubmit}>
              <Link to="/objectives" className="register_link">
                Continue
              </Link>
            </button>
          </div>
            
        </div>
      </div>
    );
  }

  render() {
    return this.ui();
  }
}

export default nextCareer;
