import React from "react";
import "../css/meeting_select.css";
import restfulApiConfig from './config.js';
import axios from "axios";
import Header from "./header";

// const now = new Date();
let meetings = [];
let user_timez ;
class SelectMeetingTime extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 
        meetingOptions: [],
        user_selected_options:[],
        currentmeetingOptions:[],
        user_timezone:"",
        loading:true,
    };

    // this.handleMeetingChange = this.handleMeetingChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }



componentDidMount(){
  document.title = "Meeting Select";
    // Get user timezone
    user_timez = Intl.DateTimeFormat().resolvedOptions().timeZone;
    this.setState({user_timezone:user_timez})
    // let result;
    axios
      .get(restfulApiConfig.api+"meetings", {
        withCredentials: true,
      })
      .then((res) => {   
        this.setState({meetingOptions: res.data});
        console.log(restfulApiConfig.api+"meetings")
        const result = this.makeState(this.state.meetingOptions)
        this.setState({currentmeetingOptions: result});

        axios
          .get(restfulApiConfig.api+"user", {
            withCredentials: true,
          })
          .then((res) => {
            if(res.data.appliedMeetings){
              this.setState({user_selected_options: res.data.appliedMeetings});
              // console.log(this.state.user_selected_options)
            }
          })

        this.setState({loading:false})
     }).catch((err) => {
          console.log(err);
        })
      

} 

makeState(meetingoptions){
  for(let i=0; i<meetingoptions.length; i++){
    if(meetingoptions[i].id===this.state.user_selected_options){
      meetings.push({
        id: meetingoptions[i].id,
        startAt: meetingoptions[i].startAt,
        checked: true
      })      
    }else{
      meetings.push({
        id: meetingoptions[i].id,
        startAt: meetingoptions[i].startAt,
        checked: false
      })
    }  
  }
  return meetings
}

// handleMeetingChange(e) {
//     // let time_id  = e.target.name;
//     console.log(e.target)
// }

      // this.setState({meetingOptions: value});
      // console.log(value)
  // }

  handleSubmit(e) {
    // console.log(e.target.value)
    if(this.state.user_selected_options.some(num => num === e.target.value)){

      axios.delete(
        restfulApiConfig.api+"user/meeting/" + e.target.value,
        {withCredentials: true},
        {
          headers : {
          'Content-Type': 'application/json',
        }}
        ).then((res) => {
          // console.log(res)
          var delete_result = this.state.user_selected_options.filter(function( usoption ) {
  
            return usoption !== e.target.value;
            
          });

        this.setState({user_selected_options:delete_result})
          // window.location.reload();
  
      }).catch((err) => {
          console.log(err)
      }
      )
    }else{
      axios.put(
        restfulApiConfig.api+"user/meeting/" + e.target.value,
        e.target.value,
        {withCredentials: true},
        {
          headers : {
          'Content-Type': 'application/json',
        }}
        ).then((res) => {
          // console.log(res)
          // var put_result = this.state.user_selected_options.push(e.target.value)
          // this.setState({user_selected_options:put_result})
          window.location.reload();
  
      }).catch((err) => {
          console.log(err)
      }
      )
    }

  

  }

  render() {
    
    let meetingOptions = this.state.meetingOptions;
    let Options = meetingOptions.map((meeting) =>{
      let mtg_start_time =new Date(meeting.startAt)
      let mNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
      // var dNames = ['Sun', 'Mon', 'Tue', 'Wed', 'Thur', 'Fri', 'Sat'];
      let hourStr = mtg_start_time.getHours() < 12 ? 'AM' : 'PM';
      let user_time = mNames[mtg_start_time.getMonth()]+" "+mtg_start_time.getDate()+"th "+mtg_start_time.getHours()+" "+hourStr
      return (      
      <div key={meeting.id.toString()} >
      <button value={meeting.id} onClick={this.handleSubmit}
      className={this.state.user_selected_options.some( num => num === meeting.id)?"checked":"unchecked"} 
      >
        {user_time}
      </button> 
    </div>)
      
    
})
    

if(this.state.loading){
  return <h1>Loading</h1>
}
    return (
        <>
        <Header/>
        <div className="select_mtg_box">
            <h2 className="select_mtg_h2" class="font-bold">
            Book your next meeting
            </h2>
            <h4 className="select_mtg_h4" ><span className="reserved">reserved</span></h4>
            <h3>{this.state.user_timezone}</h3>
            {/* <label key={this.cd}>
            <input type="checkbox" name="ALL" onChange={this.handleAllChange} />
            このエリアをチェック
            </label>
            <br /> */}
            {Options}
        </div>
        </>
    );
  }
}

export default SelectMeetingTime;