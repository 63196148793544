import React from 'react';
import '../css/jobtitle.css';
import restfulApiConfig from './config.js';
import {Link} from 'react-router-dom';
import axios from 'axios';
import PotlatchLogo from '../img/PotlatchLogo.svg';
import bg from "../img/register_bakcground.png";
// import { configure } from '@testing-library/react';


class howitworks extends React.Component {
    constructor(props) {
      super(props);
      this.state = {value: ''};
  
      this.handleChange = this.handleChange.bind(this);
      this.handleSubmit = this.handleSubmit.bind(this);
    }
    
    componentDidMount(){
      document.title = "next career";
      axios.get(restfulApiConfig.api+"user",{withCredentials: true})
      .then((res)=>{
          console.log(res.data)
      if(res.data.nextCareer!==""){
      this.setState({value: res.data.nextCareer});
      console.log(this.state.value)
      }
      }).catch((res)=>{
      console.log(res)
      });
  }

  nextcareer_data = ["start a company", "Changing careers to a startup",
                     "Changing careers to Big Company", 
                     "Get ahead in your current company",
                     "Other"];


    handleChange(event) {
      this.setState({value: event.target.value});
    }
  
    handleSubmit() {
      const nextCareer_data = {
        nextCareer: this.state.value,
      };
      // console.log(job_title_data);
      axios.patch(
        restfulApiConfig.api+"user",
        nextCareer_data,
        { withCredentials: true },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        console.log(res)
      })
      .catch((err) => {
        console.log(err);
      });
  }

  ui() {
    return (
      <div
        className="h-screen py-12 px-4 space-y-4 flex flex-col items-center text-blue-dark"
        style={{
          backgroundImage: `url(${bg})`,
        }}
      >
        <img className="h-16" src={PotlatchLogo} alt="logo" />
        <div className="lg:w-720px flex flex-col space-y-8 rounded-2xl border-blue-dark border-2 py-32px px-24px bg-white">
          <div className="flex flex-col space-y-2">
            <h2 className="font-display font-bold text-2xl">
            Thank you for answering the questions! 
            <br/>Here's how it works!</h2>
          </div>
          <div className="font-bold">1| Matching results will be emailed every Monday. Reply to schedule a meeting with your partner within 2days.</div>
          <div className="font-bold">2| Introduce yourselves to each other beforehand, exchange LinkedIn, and decide what you want to talk about.</div>
          <div className="font-bold">3| To connect with more great people on Potlatch, be sure to write a review every time! Let's make your match better.</div>
          <div className="flex flex-row justify-between">
            <button className="font-display font-bold text-blue-dark bg-white rounded-2xl py-3 px-32px">
              <Link to="/availability" className="register_back_link">
                Back
              </Link>
            </button>

            {/* <button className="font-display font-bold text-blue-dark bg-white rounded-2xl py-3 px-32px">
              <a href="https://www.linkedin.com/in/shinichi-onaka-ab15ba203/" className="register_back_link">
                More
              </a>
            </button> */}

            <div className="flex flex-row justify-end space-x-4 items-center">
                <button className="font-display font-bold text-white bg-blue-dark rounded-2xl py-3 px-32px" 
                onClick={this.handleSubmit}>
                <Link to="/" className="register_link">
                    Continue
                </Link>
                </button>
            </div>
          </div>
            
        </div>
      </div>
    );
  }

  render() {
    return this.ui();
  }
}

export default howitworks;
