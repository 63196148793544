import React from 'react';
import axios from "axios";
import { StreamChat } from 'stream-chat';
import restfulApiConfig from './config.js';
import { 
    Chat, Channel, 
    ChannelList, Window, 
    ChannelHeader, MessageList, 
    MessageInput, Thread, MessageSimple
} from 'stream-chat-react';
import 'stream-chat-react/dist/css/index.css';

// initialize the Chat client with my API key
const chatClient = StreamChat.getInstance(restfulApiConfig.chatKey);

class chatComponent extends React.Component { 
  constructor(props) {
    super(props);
    this.state = {
      data:{},
      loading:true,
      filters:null,
    };

  }

  componentDidMount() {
    document.title = "Chat";
      axios
      .get(restfulApiConfig.api+"user", {
        withCredentials: true,
      })
      .then((res) => {
        this.setState({data: res.data});
        chatClient.connectUser(
          {
              id:res.data.id,
              name: res.data.nameFirst,
              image: res.data.imageUrl,
          },
          res.data.streamToken,
      );

      this.setState({filter:{ type: 'messaging', members: { $in: [res.data.id] } }})
      this.setState({loading:false})
      })
      .catch((err) => {
        console.log(err);
      });

  }
  
      render() {

        
        if(this.state.loading){
          return <h1>Loading</h1>
        }
        return (
            <>
            <div className="chat_wrapper">           
          <Chat client={chatClient} theme={'messaging light'} className="chat">
            <ChannelList
              filters={this.state.filter}
              // sort={this.sort}
            //   List={ChannelListMessenger}
            //   Preview={ChannelPreviewMessenger}
              />
            <Channel >
              <Window>
                <ChannelHeader />
                <MessageList  />
                {/* TypingIndicator={TypingIndicator} */}
                <MessageInput   focus/>
                {/* Input="" */}
              </Window>
              <Thread Message={MessageSimple}/>
            </Channel>
          </Chat>
          </div>
          </>
        );
      }
    }

export default chatComponent;