import React from "react";
import { Link } from "react-router-dom";
import Select from "react-select";
import axios from "axios";
import "../css/objectives.css";
import restfulApiConfig from "./config.js";
import PotlatchLogo from "../img/PotlatchLogo.svg";
import bg from "../img/register_bakcground.png";

// import Bizdev from '../img/bizdev.png';
// import Brainstorm from '../img/brainstorm.png';
// import Expnewpro from '../img/exp_new_pro.png';
// import Expnewcomp from '../img/exploreothercom.png';
// import Findco from '../img/find_co.png';
// import Growyourteam from '../img/growyourteam.png';
// import Invest from '../img/invest.png';
// import Meetintrpeo from '../img/meet_intre_peo.png';
// import Mentother from '../img/ment_oth.png';
// import Orgnizeve from '../img/orgniz_eve.png';
// import Raifunds from '../img/rai_fund.png';
// import StartCompa from '../img/startcompany.png';
// import axios from 'axios';
// import { Link } from 'react-router-dom';
//コミュニティテーブルから画像を引っ張ってきて配列に入れる

// const objectives_images = [
//   Bizdev,Brainstorm,Expnewpro,Expnewcomp,
//   Findco,Growyourteam,Invest,Meetintrpeo,
//   Mentother,Orgnizeve,Raifunds,StartCompa,
// ]

const objectives_options = [
  { value: "Meet intresting product people", label: "Meet intresting product people" },
  { value: "Talking about PdM career", label: "Talking about PdM career" },
  { value: "Brainstorm with peers", label: "Brainstorm with peers" },
  { value: "Explore other company", label: "Explore other company" },
  { value: "Business development", label: "Business development" },
  { value: "Do good networking", label: "do good networking" },
  { value: "Collabrate with others", label: "collabrate with others" },
  { value: "Mentor others", label: "Mentor others" },
  { value: "Find a Mentor", label: "Find a Mentor" },
  { value: "Find a cofounder", label: "Find a cofounder" },
];

const objectives = [];
const obj_data = {
  "objectives": objectives,
};
class Objectives extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: [],
      user_data: [],
    };

    this.onChange = this.onChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    document.title = "Objective Select";

    axios
      .get(restfulApiConfig.api + "user", { withCredentials: true })
      .then((res) => {
        // console.log(res.data)
        this.setState({ user_data: res.data });
        if (res.data.objectives !== "") {
          let obj_options = [];
          obj_options = res.data.objectives
            .map((obj) => {
              return { value: obj, label: obj };
            })
          this.setState({value: obj_options});
          // console.log(this.state.value)
            // .catch((res) => {
            //   console.log(res);
            // });
        }
      });
  }

  onChange(value) {
    this.setState({ value: value });
    // console.log(this.state.value)
    for (let i = 0; i < value.length; i++) {
      objectives[i] = value[i].value;
    }
  }

  handleSubmit() {
    axios
      .patch(
        restfulApiConfig.api + "user",
        obj_data,
        { withCredentials: true },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        // console.log(res)
        // console.log(obj_data)
      })
      .catch((err) => {
        console.log(err);
      })
    // event.preventDefault();
  }

  ui() {
    return (
      <div
        className="h-screen py-12 px-4 space-y-4 flex flex-col items-center text-blue-dark"
        style={{
          backgroundImage: `url(${bg})`,
        }}
      >
        <img className="h-16" src={PotlatchLogo} alt="ロゴ" />
        <div className="lg:w-720px flex flex-col space-y-8 rounded-2xl border-blue-dark border-2 py-32px px-24px bg-white">
          <div className="flex flex-col space-y-4">
            <h2 className="font-display font-bold text-2xl">
              What topics are you interested in?
            </h2>
            <h3 className="font-display text-xl">
              This helps us match you with people who share your interests.(multiple choice)
            </h3>
          </div>
          <Select
            value={this.state.value}
            onChange={this.onChange}
            options={objectives_options}
            className="objectives_selections"
            isMulti
          />

          <div className="flex flex-row justify-between">
            <button className="font-display font-bold text-blue-dark bg-white rounded-2xl py-3 px-32px">
              <Link to="/nextcareer" className="register_back_link">
                Back
              </Link>
            </button>

            <div className="flex flex-row justify-end space-x-4 items-center">
              <p>Step 2 of 5</p>
              <button className="font-display font-bold text-white bg-blue-dark rounded-2xl py-3 px-32px" onClick={this.handleSubmit}>
                <Link to="/intro" className="register_link">
                  Continue
                </Link>
              </button>
          </div>
          </div>
        </div>
      </div>
    );
  }

  oldUI() {
    return (
      <div
        className="bg-cover bg-no-repeat bg-center"
        // style="background-image:url(../img/register_bakcground.png)"
      >
        <div className="register_flow_logo">
          <img src={PotlatchLogo} alt="ロゴ" />
        </div>
        <div className="register_flow_box">
          <h2 className="objectives_jobtitle_h2">
            Welcome, {this.state.user_data.nameFirst}{" "}
            {this.state.user_data.nameLast} <br />
            What topics are you interested in?
          </h2>
          <div className="objectives_h4">
            This helps us match you with people who share your interests.
          </div>
          <Select
            value={this.state.value}
            onChange={this.onChange}
            options={objectives_options}
            className="objectives_selections"
            isMulti
          />
          <div className="navigation_wrapper">
            <p>Step 1 of 3</p>
            <div className="register_button" onClick={this.handleSubmit}>
              <Link to="/intro" className="register_link">
                Continue
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    return this.ui();
  }
}

export default Objectives;
