import React from "react";
import axios from 'axios';
import Header from "./header";

const endpoint = "https://6a44eb5cff6b.ngrok.io/test"

  // headers = {
  //   // 'Content-Type': 'application/json',
  //   'Access-Control-Allow-Origin':'*',
  // }
  

class SubmitButton extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            
        };
      }
    
    user_data = {    
        // "friendUserId": "friend_id",
        // "id": "user_id",
        // "name": "name", 
        // "phone": "phone",未定
        // # "timezone": "timezone", 後で追加？
        // "linkedIn": "linkedin_url",
        // "linkedInImage": "linkedinimg",
        // "email": "email",  もうすでにDBに入ってる
        "location": "location",
        "jobTitle": "job_title",
        // "community": "community",
        "objective": "objectives",
        "introduction": "introduction",
    }

//location,community,jobtitle,objective,introduction,privacy policy

    submitClick = () =>{
      axios
      .post(
        endpoint, 
        this.user_data,     
        ).then((res) => {
          console.log(res)
      }).catch((error) => {
          console.log(error)
      }
      )}

    render() {
    return (
      <>
      <Header/>
      <button onClick={this.submitClick}>submit</button>
      </>
    
    );
  }
  }

export default SubmitButton;