import React from 'react';
import Header from "./header";
import '../css/user_profile.css';
import restfulApiConfig from './config.js';
import axios from "axios";
import {
    // BrowserRouter as Router,
    // // Switch,
    // Route,
    Link,
  } from 'react-router-dom';

class Profile extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
          user_data: "" ,
          user_objectives:[],
        };

    //   this.handleChange = this.handleChange.bind(this);
    //   this.handleSubmit = this.handleSubmit.bind(this);
    }

componentDidMount(){
    document.title = "Profile Edit";
    axios.get(restfulApiConfig.api+"user",{withCredentials: true})
    .then((res)=>{
    this.setState({user_data: res.data});
    this.setState({user_objectives: res.data.objectives});
    // console.log(this.state.user_data.imageUrl)
    }).catch((res)=>{
    console.log(res)
    });
    }




render() {
    let each_objects=this.state.user_objectives.map((obj)=>
            <span key={obj}>{obj},</span>)
    return(

        <>

        <Header/>   
        <div className="profile_box">
        <div className="profile_image">
            <img src={this.state.user_data.imageUrl} alt="Oops"/>
        </div>
 
        <h4>{this.state.user_data.nameFirst+" "+this.state.user_data.nameLast}</h4>

        <div className="profile_jobTitle profile_list_common">{this.state.user_data.jobTitle}</div>
        <div>       
            <Link to="/UpdateJobTitle" className="profile_link">
            edit
            </Link>  
        </div>

{/* 
        <div className="profile_location profile_list_common">{this.state.user_data.location}</div>
        <div>
            <Link to="/UpdateLocation" className="profile_link">
            edit
            </Link>
        </div> */}


        <div className="profile_introduction profile_list_common">{this.state.user_data.introduction}</div>
        <div>
            <Link to="/UpdateIntro" className="profile_link">
            edit
            </Link>
        </div>

        <div className="profile_Objectives profile_list_common">
            {each_objects} 
        </div>
        <div>
            <Link to="/UpdateObjectives" className="profile_link">
            edit
            </Link>
        </div>

        </div>

        </>
    
    );
    }
}

export default Profile;
