import React from 'react';
import '../css/jobtitle.css';
import restfulApiConfig from './config.js';
import {Link} from 'react-router-dom';
import axios from 'axios';
import PotlatchLogo from '../img/PotlatchLogo.svg';
import bg from "../img/register_bakcground.png";
// import { configure } from '@testing-library/react';


class Availability extends React.Component {
    constructor(props) {
      super(props);
      this.state = {value: ''};
  
      this.handleChange = this.handleChange.bind(this);
      this.handleSubmit = this.handleSubmit.bind(this);
    }
    
    componentDidMount(){
      document.title = "Availability Select";
      axios.get(restfulApiConfig.api+"user",{withCredentials: true})
      .then((res)=>{
      if(res.data.availability!==""){
      this.setState({value: res.data.availability});
      // console.log(this.state.value)
      }
      }).catch((res)=>{
      console.log(res)
      });
  }

  // jobtitle_data = ["Executive", "Manager", "Employee", "Freelancer", "Student"];


    handleChange(event) {
      this.setState({value: event.target.value});
    }
  
    handleSubmit() {
      const availability_data = {
        availability: this.state.value,
      };
      // console.log(job_title_data);
      axios.patch(
        restfulApiConfig.api+"user",
        availability_data,
        { withCredentials: true },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        console.log(res)
      })
      .catch((err) => {
        console.log(err);
      });
  }

  ui() {
    return (
      <div
        className="h-screen py-12 px-4 space-y-4 flex flex-col items-center text-blue-dark"
        style={{
          backgroundImage: `url(${bg})`,
        }}
      >
        <img className="h-16" src={PotlatchLogo} alt="ロゴ" />
        <div className="lg:w-720px flex flex-col space-y-8 rounded-2xl border-blue-dark border-2 py-32px px-24px bg-white">
          <div className="flex flex-col space-y-2">
            <h2 className="font-display font-bold text-2xl">
              Okay, Could you share a Calendly or something like that <br/>
              or Times when you can usually meet in a week?
            </h2>
            <h3 className="font-display text-xl">
            We will arrange a meeting from the dates you provide.
            </h3>
            <p>If you don't have a Calendly, please make a &nbsp;
              <a href="https://calendly.com/" target="_blank" rel="noopener noreferrer" className="underline">
                Calendly </a> account 
            <br/>or let me know &nbsp;
              <a href="https://calendly.com/shin1_o/talk-about-potlatch" target="_blank" rel="noopener noreferrer" className="underline">
              here</a>
            </p>
            
          </div>

        <input
            id="user_intro"
            className="w-full max-h-36 px-4 py-2 rounded-xl border-2 border-gray-3 hover:border-blue-dark focus:border-blue-dark"
            value={this.state.value}
            onChange={this.handleChange}
            placeholder="https://calendly.com/ or Weekdays from 7:00 p.m. to 9:00 p.m.
            All day on weekends"
          />

          <div className="flex flex-row justify-between">
            <button className="font-display font-bold text-blue-dark bg-white rounded-2xl py-3 px-32px">
              <Link to="/linkedinurl" className="register_back_link">
                Back
              </Link>
            </button>

            <div className="flex flex-row justify-end space-x-4 items-center">
              <p>Step 5 of 5</p>
              <button
                className="font-display font-bold text-white bg-blue-dark rounded-2xl py-3 px-32px"
                onClick={this.handleSubmit}
              >
                <Link to="/howitworks" className="register_link">
                  Continue
                </Link>
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  oldUI() {
    return (
      <div className="register_flow_wrapper">
        <div className="register_flow_logo">
          <img src={PotlatchLogo} alt="ロゴ" />
        </div>
        <div className="register_flow_box">
          <h2 className="objectives_jobtitle_h2">
            What's the closest description to your role?
          </h2>
          <div className="jobtitle_h4">
            This helps your Matches understand your perspective.
          </div>
          <div className="jobtitle_select">
            <select value={this.state.value} onChange={this.handleChange}>
              <option value="" className="jt_option"></option>
              {this.jobtitle_data.map((d) => (
                <option className="jt_option" value={d} key={d}>
                  {d}
                </option>
              ))}
            </select>
          </div>
          <div className="navigation_wrapper">
            <div className="register_back_button">
              <Link to="/intro" className="register_back_link">
                Back
              </Link>
            </div>
            <p>Step 3 of 3</p>
            <div className="register_button" onClick={this.handleSubmit}>
              <Link to="/" className="register_link">
                Continue
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    return this.ui();
  }
}

export default Availability;
