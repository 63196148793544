import React from "react";
import './App.css';
import SubmitButton from "./components/SubmitButton";
import Chat from "./components/chat";
import Dashboard from "./components/dashbord";
import Location from "./components/location";
import NextCareer from "./components/nextCareer";
import Objectives from "./components/objectives";
import Intro from "./components/intro";
import LinkedInUrl from "./components/linkedinurl";
import Availability from "./components/availability";
import HowItWorks from "./components/howitworks";
import CommunitySelect from "./components/community";
import SelectMeetingTime from "./components/meeting_time_select";
import Profile from "./components/user_profile";
import UpdateLocation from "./components/updateLocation";
import UpdateObjectives from "./components/updateObjectives";
import UpdateIntro from "./components/updateIntro";
import UpdateJobTitle from "./components/updateJobtitle";
import LinkedInLogin from "./components/linkedInLogin";
import PotlatchMeeting from "./components/potlatch_meeting";
import Review from "./components/review";
import Editor from "./components/editor";
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from 'react-router-dom';


class App extends React.Component {
  constructor(props) {
      super(props);
      this.state = {
          
      };
    }


  render() {
    return(
      <>
        {/* <div className="App">
          aaaaaaaaa         
        </div> */}
        <Router>
          <Switch>
            <Route path='/chat' exact>
              <Chat />
            </Route>
            <Route path='/form' exact>
              <SubmitButton />
            </Route>
            <Route path='/' exact>
              <Dashboard />
            </Route>
            <Route path='/location' exact>
              <Location />
            </Route>
            <Route path='/nextcareer' exact>
              <NextCareer />
            </Route>
            <Route path='/objectives' exact>
              <Objectives />
            </Route>
            <Route path='/intro' exact>
              <Intro />
            </Route>
            <Route path='/linkedinurl' exact>
              <LinkedInUrl />
            </Route>
            <Route path='/availability' exact>
              <Availability />
            </Route>
            <Route path='/howitworks' exact>
              <HowItWorks />
            </Route>
            <Route path='/community' exact>
              <CommunitySelect />
            </Route>
            <Route path='/selectmeetingtime' exact>
              <SelectMeetingTime />
            </Route>
            <Route path='/user_profile' exact>
              <Profile />
            </Route>
            <Route path='/UpdateLocation' exact>
              <UpdateLocation />
            </Route>
            <Route path='/UpdateObjectives' exact>
              <UpdateObjectives />
            </Route>
            <Route path='/UpdateIntro' exact>
              <UpdateIntro />
            </Route>
            <Route path='/UpdateJobTitle' exact>
              <UpdateJobTitle />
            </Route>
            <Route path='/LinkedInLogin' exact>
              <LinkedInLogin />
            </Route>
            <Route path='/Editor' exact>
              <Editor />
            </Route>
            <Route path='/PotlatchMeeting/:id' component={PotlatchMeeting}>
            </Route>
            <Route path='/review/:id' component={Review}>
            </Route>
          </Switch>          
        </Router>
      </>
    )

  }
}

// switch (process.env.NODE_ENV) {
//   case 'development':
//     axios.defaults.baseURL = 'http://localhost:3000/api';
//     break;
//   case 'production':
//     axios.defaults.baseURL = 'https://reword-web.herokuapp.com/api';
//     break;
//   default:
//     axios.defaults.baseURL = 'http://localhost:3000/api';
// }
export default App;
