import React from "react";
import "../css/location.css";
import { Link } from "react-router-dom";
import axios from "axios";
import PotlatchLogo from '../img/PotlatchLogo.svg';

// const endpoint = "https://v1api-dev.potlatch.io/user"
const endpoint = "https://v1api.potlatch.io/user"

class location extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 
      value: "" ,
      user_data:[],
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }


  componentDidMount(){
    document.title = "Location Select";
    axios.get(endpoint,{withCredentials: true})
    .then((res)=>{
      this.setState({user_data: res.data})
    if(res.data.location!==""){
    this.setState({value: res.data.location});
    }
    console.log(this.state.value)
    }).catch((res)=>{
    console.log(res)
    });
    }
  handleChange(event) {
    this.setState({ value: event.target.value });
  }

  handleSubmit() {
    const location_data = {
      location: this.state.value,
    };
    console.log(location_data);
    axios.patch(
      endpoint,
      location_data,
      {withCredentials: true},
      {
        headers : {
        'Content-Type': 'application/json',
      }}
      ).then((res) => {
        console.log(res)

    }).catch((err) => {
        console.log(err)
    }
    )
  }

  render() {
    return (
      <div className="location_wrapper">       
          <div className="location_logo"><img src={PotlatchLogo} alt="ロゴ"/></div>
          <div className="location_box">
            <h1 className="location_h1">
              Welcome, {this.state.user_data.nameFirst} {this.state.user_data.nameLast} <br/>
              Where are you currently located?
            </h1>
            <p className="location_reason_p">
              This helps us match you with people around you
            </p>
            <h4 className="country_city">Country/City</h4>
            <div className="location_input">
              <input
                type="text"
                name="name"
                id="user_location"
                value={this.state.value}
                onChange={this.handleChange}
              />
            </div>

            <div className="navigation_wrapper">
              <p>Step 1 of 4</p>
              <div
                className="location_button"
                onClick={this.handleSubmit}
              >
                <Link to="/objectives" className="location_link">
                  Continue
                </Link>
              </div>
            </div>
          </div>
      </div>
    );
  }
}

export default location;
